//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-820:_1648,_4340,_7020,_8404,_7436,_1548,_2352,_4636;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-820')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-820', "_1648,_4340,_7020,_8404,_7436,_1548,_2352,_4636");
        }
      }catch (ex) {
        console.error(ex);
      }