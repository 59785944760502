import { SearchPage } from 'Components'
import TemplateSearchPage from 'Stores/basicsmart/Templates/SearchPage.rt'
import { SearchBoxDialogButton } from 'Components'
import TemplatesearchBoxdialogButtonAdaptable from 'Stores/_default-store/Templates/searchBox/dialogButtonAdaptable.rt'
import { Garage } from 'Components'
import TemplatefitmentSearchgarage from 'Stores/_default-store/Templates/fitmentSearch/garage.rt'
import { VehicleWidget } from 'Components'
import TemplatefitmentSearchhomeVehicleWidget from 'Stores/_default-store/Templates/fitmentSearch/homeVehicleWidget.rt'
import TemplatefitmentSearchverifyFitment from 'Stores/_default-store/Templates/fitmentSearch/verifyFitment.rt'
import { FacetTiles } from 'Components'
import TemplatefacetTileshierarchicalContainer from 'Stores/_default-store/Templates/facetTiles/hierarchicalContainer.rt'
import { SearchHeader } from 'Components'
import { SearchResult } from 'Components'
import { FacetPanel } from 'Components'
import { ContextDialog } from 'Components'
import TemplatefitmentSearchdialog from 'Stores/_default-store/Templates/fitmentSearch/dialog.rt'
import { SearchBoxDialog } from 'Components'
import TemplateSearchBoxDialog from 'Stores/_default-store/Templates/SearchBoxDialog.rt'

export const compMap = {
  SearchPage,
SearchBoxDialogButton,
Garage,
VehicleWidget,
FacetTiles,
SearchHeader,
SearchResult,
FacetPanel,
ContextDialog,
SearchBoxDialog
} 
export const tplMap = {
  'SearchPage': TemplateSearchPage,
'searchBox/dialogButtonAdaptable': TemplatesearchBoxdialogButtonAdaptable,
'fitmentSearch/garage': TemplatefitmentSearchgarage,
'fitmentSearch/homeVehicleWidget': TemplatefitmentSearchhomeVehicleWidget,
'fitmentSearch/verifyFitment': TemplatefitmentSearchverifyFitment,
'facetTiles/hierarchicalContainer': TemplatefacetTileshierarchicalContainer,
'fitmentSearch/dialog': TemplatefitmentSearchdialog,
'SearchBoxDialog': TemplateSearchBoxDialog
};