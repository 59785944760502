const baseFitmentSearchFields = ['Year', 'Make', 'Model'];
const extraFitmentSearchFields = [];

const categorySelectionPageUrl = '/pages/parts';

const updateCallback = () => {
  const shopCurrency = window.Shopify.currency.active;
  const currentCurrency = window.Currency.cookie.read();
  window.Currency.convertAll(shopCurrency, currentCurrency);
};

export default {
  platform: 'shopify',
  SearchRequestDefaults: {
    pageSize: 36,
  },
  fitmentSearch: {
    baseFields: baseFitmentSearchFields,
    extraFields: extraFitmentSearchFields,
    categorySelectionPages: { pathname: categorySelectionPageUrl, field: 'collections' },
    categorySelectionPageRedirect: true,
  },
  product: {
    searchResultsImageWidth: 240,
    searchResultsImageHeight: 240,
    crop: 'crop_top',
    noImageSrc: 'https://client.convermax.com/static/img/noimage_240.png',
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'SearchBoxDialogButton',
      location: 'header .header_search',
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'Garage',
      location: { insertAfter: '.header_cart', class: 'header_cart' },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: { selector: 'body.template-index #cm_ymm-home', class: 'model-selector-home' },
      template: 'fitmentSearch/homeVehicleWidget',
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'VerifyFitment', // TODO: not setup
      type: 'VehicleWidget',
      location: { selector: 'change-me' },
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: { firstChildOf: '.page_container .rte' },
      template: 'facetTiles/hierarchicalContainer',
      isMultiLevel: true,
      visibleIf: () => window.location.pathname === categorySelectionPageUrl,
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'title': 'Name, A-Z',
        'title:desc': 'Name, Z-A',
        'collection_sort': 'Featured',
        'price': 'Price, Low to High',
        'price:desc': 'Price, High to Low',
        'published_at': 'Date, Old to New',
        'published_at:desc': 'Date, New to Old',
      },
    },
    {
      name: 'SearchResult',
      updateCallback,
    },
    {
      name: 'FacetPanel',
    },
    {
      name: 'facet-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
